.App {
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
}

.resourceLinks {
  padding-bottom: 50px;
}

.resourceLinks > ul {
  display: inline-block;
  text-align: left;
}

.addLoanButton {
  margin-top: 1em;
  font-size: 12pt;
}

body {
  font-family: Tahoma, Geneva, sans-serif;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Number-Input {
  width: 5em;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

th {
  background-color: rgb(162, 221, 221);
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.graph {
  height: 30vh;
  margin: auto;
  width: 50%;
  padding: 10px;
}

hr {
  width: 60%;
}

.duplicateLoan {
  background-color: LightCoral;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: gray;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
